@import 'fonts.css';
/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* See _head.html for @font-face */
@layer utilities {
  .font-optical-wide {
    font-variation-settings: 'wdth' 125;
  }
  
  .font-optical-normal {
    font-variation-settings: 'wdth' 100;
  }
}

@layer base {
  .prose code {
    padding: 0 0.2em;
    background: #eee;
  }
  .prose code::before, .prose code::after {
    content: "";
  }
  
  .prose mark {
    @apply bg-sky-300/50 font-optical-wide px-1 font-bold;
  }
  
  .prose blockquote strong {
    @apply !font-extrabold;
  }
  
  .cta-btn, .prose a.cta-btn {
    @apply text-sm md:text-base border-[6px] md:border-8 border-stone-900 px-4 md:px-8 py-2 md:py-3 uppercase font-optical-wide font-extrabold bg-amber-400 no-underline;
  }
  
  .cta-btn:hover,
  .cta-btn:focus {
    background-color: #F3CD49;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 1000'%3E%3Cdefs%3E%3Cpath id='a' fill='%23FFFFFF' fill-opacity='0.16' d='m800 500 610.5-1057.6A1215 1215 0 0 0 800-721V500zm1057.6-610.5L800 500h1221a1215 1215 0 0 0-163.4-610.5zM800 500 189.4-557.6a1226.7 1226.7 0 0 0-447 447L800 500zm0 0 610.6 1057.6a1227.7 1227.7 0 0 0 446.9-446.9L800 500zm0 0L189.5 1557.6A1215 1215 0 0 0 800 1721V500zm0 0H-421a1215 1215 0 0 0 163.4 610.5L800 500z' /%3E%3C/defs%3E%3Cuse href='%23a' transform='rotate(0 800 500)'%3E%3CanimateTransform attributeName='transform' type='rotate' dur='6s' begin='-3s' values='0 800 500%3B60 800 500' calcMode='spline' keySplines='.7 0 .3 1' repeatCount='5' /%3E%3C/use%3E%3Cuse href='%23a' transform='rotate(142.5 800 500)'%3E%3C/use%3E%3Cuse href='%23a' transform='rotate(190 800 500)'%3E%3CanimateTransform attributeName='transform' type='rotate' dur='6s' values='190 800 500%3B130 800 500' calcMode='spline' keySplines='.6 0 .4 1' repeatCount='indefinite' /%3E%3C/use%3E%3Cuse href='%23a' transform='rotate(135 800 500)'%3E%3C/use%3E%3C/svg%3E");
    background-size: 200%;
    background-position: 50% 100%;
  }
  
  body {
    --sl-input-font-family: 'Recursive';
  }
}