@font-face {
  font-family: 'Recursive';
  src: local('Recursive'), url('/fonts/Recursive_VF_1.084--subset-GF_latin_basic.woff2') format('woff2');
}

@font-face {
  font-family: 'Mona Sans';
  src:
    url("/fonts/Mona-Sans.woff2") format('woff2 supports variations'),
    url("/fonts/Mona-Sans.woff2") format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}